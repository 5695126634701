<template>
    <div class="w-full">
      <div class="formList">
        <div v-for="(check, index) in formsListData" :key="check.check_id" class="formListItem ">
          <div class="flex items-center h-10 border-2 rounded-xl py-6 border-box-stroke">
              <div class="flex pl-4 w-full truncate capitalize py-2"> 
                  <p class="flex"  :class="{ formListItem__disabled : check.disabled || disabled  }">
                      <span class="pr-4 font-semibold text-md self-center">{{ index + 1 }} .</span>
                      <span class="grid">
                          <span class="truncate" :title="check.name">{{ check.name }}</span>
                          <span class="truncate text-sm text-blue-600" :title="check.entityTypesLabel">{{ check.entityTypesLabel }}</span>
                      </span>
                  </p>
              </div>
              <div class="px-4 flex justify-center w-14 py-2 items-center" v-if="!disabled">
                  <button 
                      v-allow="'package.edit'" 
                      class="cursor-pointer bg-gray-100 text-xs text-base-content-300 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" 
                      :class="{ formListItem__disabled : check.disabled || disabled }"
                      @click="removeCheck(check)"
                  >
                      <font-awesome-icon icon="times" class="fill-current text-current" />
                  </button>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "forms-list",
  props: {
    formsList: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    } 
  },
  methods: {
    removeCheck(check) {
      this.$emit('removeCheck', check);
    },
  },
  computed: {
     formsListData() {
        return this.formsList.map(check =>({
                  ...check, 
                  name: check.name || check.check_name,
                  entityTypesLabel: (check.entity_types || []).map(type=>(type.name)).join(', ')
              }));
     }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep {
      .formList {
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          column-gap: 15px;
          row-gap: 15px;
      }
      .formListItem {
          &__disabled {
              opacity: 0.5;
              pointer-events: none;
          }
      }
  }
</style>