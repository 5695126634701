<template>
  <span class="loader" :class="getClassWithProps()"></span>
</template>

<script>
export default {
    props: {
        size: {
            default: 10,
            Type: Number
        },
        color: {
            default: 'primary',
            Type: String
        }
    },
    methods: {
        getClassWithProps() {
            let classes = ' '
            classes+= `w-${this.size} h-${this.size} border-primary`
            return classes
        }
    }
};
</script>

<style>
.loader {
  /* width: 48px;
  height: 48px; */
  /* border: 5px solid hsla(var(--pf)/var(--bg-opacity)); */
  border-width: 5px;
  border-style: solid;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>