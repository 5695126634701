export default {
  name: "neo-checkbox",
  components: {},
  props: ["value", "label", "bg", "borderColor","disabled", 'labelContainerClassList', 'updateSelectAllVal','isSelectAllInput'],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
